<template>
    <div class="container shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] text-6xl rounded-xl p-6 bg-white">
        <div class="flex justify-between w-full">
            <h5 class="text-sm pe-4 font-light mt-1 text-[#202224] max-w-[200px]">
                {{ data.topic }}
            </h5>
            <div class="icon-container" :style="{ backgroundColor: data.color }">
                <span class="text-xl"><i :class="data.icon" :style="{ color: data.iconcolor }"></i></span>
            </div>
        </div>
        <div v-if="isLoading" class="figures text-lg font-bold">
            <ScaleLoader color="#000"></ScaleLoader>
        </div>
        <div v-else class="figures">
            <span :class="{ 'small-font': data.small }">
                {{ formatNumbers(data.figures) }}
            </span>
        </div>
        <div v-if="data.network && !isLoading" class="count mt-2 font-bold" style="font-size: small;">
            {{ data.network_figures }}
        </div>
        <div v-if="data.network && isLoading" class="figures text-lg font-bold">
            <ScaleLoader color="#000"></ScaleLoader>
        </div>
        <small v-if="data.percentage && !isLoading" class="description">
            <span :style="{ color: data.percentage >= 0 ? '#00B69B' : 'red' }">
                <span class="text-xl">
                    <i
                        :class="data.percentage >= 0 ? 'fa-solid fa-arrow-trend-up' : 'fa-solid fa-arrow-trend-down'"></i>
                </span>
                {{ Math.abs(data.percentage) }}% <span style="color: black;">{{ data.customWord }}</span>
            </span>
        </small>
    </div>
</template>

<script lang="ts">
import { inject, computed, Ref } from 'vue';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';

export default {
    name: "CardDesign",
    components: {
        ScaleLoader,
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        source: {
            type: String,
            default: 'homepage'
        },
    },
    setup(props: any) {
        // Explicitly type the injected values
        const loadingOne = inject<Ref<boolean>>('loadingOne');
        const loadingTwo = inject<Ref<boolean>>('loadingTwo');

        // Determine which loading state to use based on the topic
        const isLoading = computed(() => {
            if (props.data.topic === 'TOTAL ROUTERS' || props.data.topic === 'TOTAL SMES') {
                return loadingOne?.value ?? false;
            } else {
                return loadingTwo?.value ?? false;
            }
        });

        return {
            isLoading,
        };
    },
    methods: {
        formatNumbers(num: number) {
            if (typeof num === 'number' && Number.isInteger(num)) {
                let numStr = num.toString();
                let parts = numStr.split('.');
                let integerPart = parts[0];
                let decimalPart = parts.length > 1 ? '.' + parts[1] : '';
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return integerPart + decimalPart;
            } else {
                return num;
            }
        }
    },
};
</script>

<style scoped>
.container {
    border-radius: 7px;
    box-shadow: 1px 1px 10px 1px rgb(170, 168, 168);
    padding: 10px;
    height: 130px;
}

.topic .icon {
    height: 30px;
}

.topic .topic-desc {
    font-weight: light;
    font-size: small;
    color: #202224;
}

.figures {
    font-weight: bolder;
    font-size: x-large;
    position: absolute;
}

.icon-container {
    width: 40px;
    height: 40px;
    border-radius: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-container #icon {
    background-color: transparent;
    font-size: 20px;
    margin-right: 5%;
    opacity: 100%;
}

.description {
    color: #202224;
    font-size: small;
}

.small-font {
    font-size: 0.7em;
    margin-top: 40%;
}
</style>
