<template>
  <DashboardHeader title="Dashboard" :icon="icon.dashboard">
    <template v-slot:aside>
    </template>
  </DashboardHeader>
  <suspended-page-message v-if="!loggedInUser.sme?.is_active"></suspended-page-message>
  <div v-else class="w-full my-3">
    <div class="min-server text-right mb-2 flex items-center">
      <select v-model="selectedRouter" @change="handleRouterChange"
        class="text-[14px] max-w-[250px] ml-auto border-[0rem] bgwhite rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1">
        <option value="all">All Boxes</option>
        <option v-for="router in loggedInUser.partner.routers" :key="router.serial_number"
          :value="router.serial_number">
          {{ router.ssid ? router.ssid + ' - ' + router.serial_number : router.serial_number }}
        </option>
      </select>
    </div>

    <div class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100 bg-white mt-6">
      <TokenAnalyticsChart :data="tokenAnalyticsData" @filterChange="fetchTokenAnalyticsData"
        :currentTimeRange="currentTimeRange" />
    </div>

    <div class="w-full lg:flex my-6 justify-between">
      <!-- JOINED USERS ANALYTICS -->
      <SmeAnalytics :weekly_data=weeklyUsers :monthly_data=monthlyUsers :yearly_data=yearlyUsers />

      <!-- ROUTER MAP -->
      <div class="bg-white lg:w-[49%] min-h-[21.432rem] rounded-[0.938rem] pt-4 pb-6 px-6 shadow lg:mb-0 mb-5">
        <span class="font-poppins font-semibold text-[0.9rem] text-[#212121]">Map</span>
        <div class="w-full h-[400px] mt-3" id="map"></div>
      </div>

    </div>

  </div>
</template>

<script lang="ts">
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent, ref, provide } from 'vue';

import axios from '../../axios-interceptor';
import { Router } from '@/interfaces/router';
import SmeAnalytics from '../../components/markup/SmeAnalytics.vue';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import {
  routerTableHeaders as _routerTableHeaders,
  routerTableIcons as _routerTableIcons,
  dashboardHeadericon,
} from '../../helpers/stub_data/dashboard_routers';
import instance from '@/axios-interceptor';
import { DataUsage, ConnectedClients } from '@/interfaces/router';
import {
  labelsMonth,
  currentMonth,
  currentYear,
  weekStartDate,
  labelsYear,
} from '../../helpers/stub_data/charts';

import { TokenAnalyticsPayload } from '@/interfaces/router';
import TokenAnalyticsChart from '@/components/Common/TokenAnalyticsChart.vue';


export default defineComponent({
  name: 'HomePage',
  components: {
    SmeAnalytics,
    DashboardHeader,
    TokenAnalyticsChart,
  },
  data() {
    return {
      insightAnalytics: {} as any,
      wifiAnalytics: {} as any,
      selectedRange: 'all_days',
      routersAvgSpeed: {
        average_upload_speed: 0,
        average_download_speed: 0,
      },
      routersNetUsage: {
        total_data_received_mb: 0,
        total_data_transmitted_mb: 0,
      },
      networkUsagePerMonth: [] as DataUsage[],
      connectedClientsPerMonth: [] as ConnectedClients[],
      tokenAnalyticsData: null,
      selectedRouter: 'all',
      routerStatus: false,
    }
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Serial Number');
    const routers = ref<Router[]>([]);
    const routerTableHeaders = ref([..._routerTableHeaders]);
    const routerTableIcons = ref([..._routerTableIcons]);
    const accessToken = ref(
      'pk.eyJ1IjoiZ3MwMWhhbiIsImEiOiJjbGtjamU3dmEwN2pwM2pxam9taGZ0cTJ6In0.8_zydJrYKh6734GskrBRqA'
    );
    const fetchingRouters = ref(false);
    const monthlyUsers = ref({})
    const weeklyUsers = ref({})
    const yearlyUsers = ref({})

    const fetchingTokenAnalyticsData = ref(false);
    const currentTimeRange = '3months';

    provide('loadingOne', false);
    provide('loadingTwo', fetchingTokenAnalyticsData);

    const mapStyle = ref({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [12.550343, 55.665957],
      zoom: 12,
    });

    return {
      fetchingRouters,
      routers,
      routerTableHeaders,
      routerTableIcons,
      tableName,
      icon,
      accessToken,
      monthlyUsers,
      weeklyUsers,
      yearlyUsers,
      mapStyle,
      fetchingTokenAnalyticsData,
      currentTimeRange,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
  },
  methods: {
    fetchRouters() {
      this.fetchingRouters = true;

      axios
        .get(`routers/?sme=${this.loggedInUser.sme.id}`)
        .then((response: { data: any }) => {
          this.routers = response.data.results as Router[];
          this.fetchingRouters = false;

          // Filter and get the first 20 valid routers (with lat and lon available)
          const validRouters = this.routers
            .filter((router) => router.lat && router.lon)
            .slice(0, 20);

          if (validRouters.length) {
            this.initializeMap(validRouters, true);
          } else {
            this.initializeMap([], false);
          }
        })
        .catch(() => {
          this.fetchingRouters = false;
        });
    },
    getWifianlytics() {
      if (!this.loggedInUser.sme.routers.length) {
        return;
      }

      const formData = {
        sme: this.loggedInUser.sme.id,
        router_serial_numbers: this.loggedInUser.sme.routers.map((router: any) => router.serial_number),
      };
      instance
        .post(`analytics/wifianalytics/`, formData)
        .then((response: { data: any }) => {
          this.wifiAnalytics = response.data.results;
          console.log(response.data)
          this.updateActiveData();
        })
        .catch((error: any) => {
          console.log(error);
        });
    },

    fetchNetworkUsageAndConnectedClientsAnalytics(router: number) {
      const formData = {
        sme: this.loggedInUser.sme.id,
        router,
      };

      instance
        .post('analytics/network-usage-and-connected-clients/', formData)
        .then((response: { data: any }) => {

          this.networkUsagePerMonth = response.data.data_usage;
          this.connectedClientsPerMonth = response.data.connected_clients;
        })
        .catch((error: any) => {
          console.log(error);
        });
    },

    getCustomerInsights(router: number) {
      const formData = {
        sme: this.loggedInUser.sme.id,
        router,
      };
      instance
        .post(`analytics/insight/`, formData)
        .then((response: { data: any }) => {
          this.insightAnalytics = response.data.results;
          this.updateActiveData();
        })
        .catch((error: any) => {
          console.log(error);
        });
    },

    getUsersMonthlyAnalytics() {
      instance
        .get(`analytics/new-users/?start_date=${currentYear}-${currentMonth}-${labelsMonth[0]}&end_date=${currentYear}-${currentMonth}-${new Date().getDate()}`)
        .then((response: { data: any }) => {
          this.monthlyUsers = response.data.new_users.weekly;

        })
        .catch((error: any) => {
          console.log(error);
        });
    },

    getUsersWeeklyAnalytics() {
      instance
        .get(`analytics/new-users/?start_date=${currentYear}-${currentMonth}-${weekStartDate.getDate()}&end_date=${currentYear}-${currentMonth}-${new Date().getDate()}`)
        .then((response: { data: any }) => {
          this.weeklyUsers = response.data.new_users.weekly;
        })
        .catch((error: any) => {
          console.log(error);
        });
    },

    getUsersYearlyAnalytics() {
      instance
        .get(`/analytics/new-users/?start_year=${labelsYear[0]}&end_year=${labelsYear[labelsYear.length - 1]}`)
        .then((response: { data: any }) => {
          this.yearlyUsers = response.data.new_users.yearly_range;
        })
        .catch((error: any) => {
          console.log(error);
        });
    },

    updateActiveData() {
      const range = this.selectedRange;
      this.wifiAnalytics = {
        ...this.wifiAnalytics,
        currently_connected:
          this.wifiAnalytics.currently_connected_ranges[range],
        finished_partner_vouchers:
          this.wifiAnalytics.finished_partner_vouchers_ranges[range],
        router_restart_partner_vouchers:
          this.wifiAnalytics.router_restart_partner_vouchers_ranges[range],
        token_failed: this.wifiAnalytics.token_failed_ranges[range],
        total_device: this.wifiAnalytics.total_device_ranges[range],
        totals_generated: this.wifiAnalytics.totals_generated_ranges[range],
        unfinished_partner_voucher:
          this.wifiAnalytics.unfinished_partner_voucher_ranges[range],
        users_not_access_system:
          this.wifiAnalytics.users_not_access_system_ranges[range],
        average_duration: this.wifiAnalytics.average_duration_ranges[range],
      };
      this.insightAnalytics = {
        ...this.insightAnalytics,
        dwell_time: this.insightAnalytics.dwell_time_ranges[range],
        first_time_visitor:
          this.insightAnalytics.first_time_visitor_ranges[range],
        peek_time: this.insightAnalytics.peek_time_ranges[range],
        phone_model: this.insightAnalytics.phone_model_ranges[range],
        returned_customer:
          this.insightAnalytics.returned_customer_ranges[range],
        unique_customer: this.insightAnalytics.unique_customer_ranges[range],
      };
    },

    async fetchTokenAnalyticsData(newTimeRange: string) {
      if (!this.loggedInUser.sme.routers.length) {
        return;
      }

      const payload: TokenAnalyticsPayload = {
        sme: this.loggedInUser.sme.id,
      };

      if (this.selectedRouter === 'all') {
        payload.router_serial_numbers = this.loggedInUser.sme.routers.map((router: any) => router.serial_number);
      } else {
        payload.router_serial_number = this.selectedRouter;
      }

      this.currentTimeRange = newTimeRange;

      try {
        this.fetchingTokenAnalyticsData = true;
        const response = await instance.post(`/analytics/wifi-v2/?time_range=${newTimeRange}`, payload);
        this.tokenAnalyticsData = response.data;
        this.fetchingTokenAnalyticsData = false;

        // Update router status if a single router is selected
        if (this.selectedRouter !== 'all') {
          this.routerStatus = response.data.is_online || false;
        }
      } catch (error) {
        this.fetchingTokenAnalyticsData = false;
        console.error('Error fetching data:', error);
      }
    },

    handleRouterChange() {
      this.fetchTokenAnalyticsData(this.currentTimeRange);
    },

    initializeMap(routers: Router[], markLocation: boolean) {
      mapboxgl.accessToken = this.accessToken;

      const map = new mapboxgl.Map(this.mapStyle);

      if (routers.length && markLocation) {
        // Calculate clusters and add them to the map as a source
        map.on('load', function () {
          map.addSource('clusters', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: routers.map((router) => ({
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [router.lon, router.lat],
                },
                properties: {
                  id: router.id,
                  ip_address: router.ip_address,
                  serial_number: router.serial_number,
                  location_name: router.location_name,
                },
              })),
            },
            cluster: true,
            clusterMaxZoom: 15, // Max zoom to cluster points on
            clusterRadius: 50, // Radius of each cluster when clustering points (pixels)
          });

          // Add cluster layer
          map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'clusters',
            filter: ['has', 'point_count'],
            paint: {
              'circle-color': [
                'step',
                ['get', 'point_count'],
                '#51bbd6',
                10,
                '#f1f075',
                100,
                '#f28cb1',
              ],
              'circle-radius': [
                'step',
                ['get', 'point_count'],
                20,
                10,
                30,
                100,
                40,
              ],
            },
          });

          // Add cluster count layer
          map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'clusters',
            filter: ['has', 'point_count'],
            layout: {
              'text-field': '{point_count_abbreviated}',
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12,
            },
          });
          // Add unclustered points layer
          map.addLayer({
            id: 'unclustered-point',
            type: 'circle',
            source: 'clusters',
            filter: ['!', ['has', 'point_count']],
            paint: {
              'circle-color': '#11b4da',
              'circle-radius': 8,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#fff',
            },
          });

          // Add click event for clusters
          map.on('click', 'clusters', function (e: any) {
            const features = map.queryRenderedFeatures(e.point, {
              layers: ['clusters'],
            });
            console.log('Clustered Click: ', features);
            const clusterId = features[0].properties.cluster_id;
            map
              .getSource('clusters')
              .getClusterExpansionZoom(
                clusterId,
                function (err: any, zoom: number) {
                  if (err) return;
                  map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom,
                  });
                }
              );
            const source = map.getSource('clusters');
            source.getClusterLeaves(
              clusterId,
              Infinity,
              0,
              function (err: any, leafFeatures: any[]) {
                if (err) return;
                if (leafFeatures.length > 0) {
                  const popup = new mapboxgl.Popup()
                    .setLngLat(features[0].geometry.coordinates)
                    .setHTML(generatePopupHTML(leafFeatures))
                    .addTo(map);
                }
              }
            );
          });

          // Show popup for individual points
          map.on('click', 'unclustered-point', function (e: any) {
            console.log('Unclustered Click: ', e);
            const coordinates = e.features[0].geometry.coordinates.slice();
            const properties = e.features[0].properties;
            new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(
                `<p>Serial No: ${properties.serial_number} - IP Address: ${properties.ip_address}</p>`
              )
              .addTo(map);
          });

          // Function to generate popup HTML for clustered points
          function generatePopupHTML(features: any[]) {
            let html = '<div>';
            features.forEach((feature) => {
              const properties = feature.properties;
              html += `<p>Serial No: ${properties.serial_number} - IP Address: ${properties.ip_address}</p>`;
            });
            html += '</div>';
            return html;
          }
        });
      }

      // If there are routers, calculate the average center to set the map center
      if (routers.length) {
        const centerLngLat = routers.reduce(
          (acc, router) => [acc[0] + router.lon, acc[1] + router.lat],
          [0, 0]
        );
        const averageLngLat = [
          centerLngLat[0] / routers.length,
          centerLngLat[1] / routers.length,
        ];

        map.setCenter(averageLngLat);
      } else {
        // Set a default center if no routers are available
        map.setCenter([30.0557, -1.9397]);
      }

      // Optionally, set a specific zoom level to fit all the markers within the map view
      map.setZoom(8); // Adjust the zoom level as needed to fit the markers nicely
    },
  },
  created() {
    this.getWifianlytics();
    this.fetchTokenAnalyticsData('3months');
    this.fetchRouters();
    this.getUsersMonthlyAnalytics();
    this.getUsersWeeklyAnalytics();
    this.getUsersYearlyAnalytics();
  },
});
</script>



<style>
.card {
  display: flex;
  justify-content: space-between;
}

.card .card-holder {
  width: 23%;

}
</style>