import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] text-6xl rounded-xl p-6 bg-white" }
const _hoisted_2 = { class: "flex justify-between w-full" }
const _hoisted_3 = { class: "text-sm pe-4 font-light mt-1 text-[#202224] max-w-[200px]" }
const _hoisted_4 = { class: "text-xl" }
const _hoisted_5 = {
  key: 0,
  class: "figures text-lg font-bold"
}
const _hoisted_6 = {
  key: 1,
  class: "figures"
}
const _hoisted_7 = {
  key: 2,
  class: "count mt-2 font-bold",
  style: {"font-size":"small"}
}
const _hoisted_8 = {
  key: 3,
  class: "figures text-lg font-bold"
}
const _hoisted_9 = {
  key: 4,
  class: "description"
}
const _hoisted_10 = { class: "text-xl" }
const _hoisted_11 = { style: {"color":"black"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScaleLoader = _resolveComponent("ScaleLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", _hoisted_3, _toDisplayString($props.data.topic), 1),
      _createElementVNode("div", {
        class: "icon-container",
        style: _normalizeStyle({ backgroundColor: $props.data.color })
      }, [
        _createElementVNode("span", _hoisted_4, [
          _createElementVNode("i", {
            class: _normalizeClass($props.data.icon),
            style: _normalizeStyle({ color: $props.data.iconcolor })
          }, null, 6)
        ])
      ], 4)
    ]),
    ($setup.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_ScaleLoader, { color: "#000" })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("span", {
            class: _normalizeClass({ 'small-font': $props.data.small })
          }, _toDisplayString($options.formatNumbers($props.data.figures)), 3)
        ])),
    ($props.data.network && !$setup.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($props.data.network_figures), 1))
      : _createCommentVNode("", true),
    ($props.data.network && $setup.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_ScaleLoader, { color: "#000" })
        ]))
      : _createCommentVNode("", true),
    ($props.data.percentage && !$setup.isLoading)
      ? (_openBlock(), _createElementBlock("small", _hoisted_9, [
          _createElementVNode("span", {
            style: _normalizeStyle({ color: $props.data.percentage >= 0 ? '#00B69B' : 'red' })
          }, [
            _createElementVNode("span", _hoisted_10, [
              _createElementVNode("i", {
                class: _normalizeClass($props.data.percentage >= 0 ? 'fa-solid fa-arrow-trend-up' : 'fa-solid fa-arrow-trend-down')
              }, null, 2)
            ]),
            _createTextVNode(" " + _toDisplayString(Math.abs($props.data.percentage)) + "% ", 1),
            _createElementVNode("span", _hoisted_11, _toDisplayString($props.data.customWord), 1)
          ], 4)
        ]))
      : _createCommentVNode("", true)
  ]))
}