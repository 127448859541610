import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full my-3" }
const _hoisted_2 = { class: "bg-white w-full min-h-[25rem] p-6 overflow-auto relative" }
const _hoisted_3 = {
  class: "bg-light justify-center text-6xl rounded-xl p-6 bg-gray-100",
  id: "routers",
  role: "tabpanel",
  "aria-labelledby": "routers"
}
const _hoisted_4 = { class: "min-server text-right mb-2 flex items-center" }
const _hoisted_5 = ["value"]
const _hoisted_6 = {
  key: 0,
  class: "dotonline ml-2"
}
const _hoisted_7 = {
  key: 1,
  class: "dotoffline ml-2"
}
const _hoisted_8 = {
  class: "tabs-block",
  id: "tabs-block"
}
const _hoisted_9 = { class: "mb-3" }
const _hoisted_10 = {
  class: "flex flex-wrap text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_11 = { class: "mr-2" }
const _hoisted_12 = { class: "mr-2" }
const _hoisted_13 = { class: "mr-2" }
const _hoisted_14 = { class: "mr-2" }
const _hoisted_15 = { id: "myTabContent" }
const _hoisted_16 = {
  key: 0,
  class: "shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl p-6 bg-white",
  id: "profile",
  role: "tabpanel",
  "aria-labelledby": "profile-tab"
}
const _hoisted_17 = { class: "mt-2" }
const _hoisted_18 = { class: "w-full" }
const _hoisted_19 = { class: "h-4/5 block mt-6 p-4 bg-white shadow-gray-950 border-solid border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700" }
const _hoisted_20 = { class: "mt-8" }
const _hoisted_21 = {
  key: 1,
  class: "text-sm"
}
const _hoisted_22 = {
  key: 1,
  class: "shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl p-6 bg-gray-100",
  id: "profile",
  role: "tabpanel",
  "aria-labelledby": "profile-tab"
}
const _hoisted_23 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6" }
const _hoisted_24 = {
  key: 2,
  class: "shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl md:p-6 p-4 bg-white",
  id: "profile",
  role: "tabpanel",
  "aria-labelledby": "profile-tab"
}
const _hoisted_25 = {
  class: "shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl md:p-6 p-4 bg-white",
  id: "service-status",
  role: "tabpanel",
  "aria-labelledby": "service-status-tab"
}
const _hoisted_26 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 pt-2" }
const _hoisted_27 = { class: "justify-center" }
const _hoisted_28 = { class: "justify-center" }
const _hoisted_29 = { class: "justify-center" }
const _hoisted_30 = { class: "justify-center" }
const _hoisted_31 = { class: "justify-center" }
const _hoisted_32 = { class: "justify-center" }
const _hoisted_33 = { class: "justify-center" }
const _hoisted_34 = { class: "justify-center" }
const _hoisted_35 = { class: "justify-center" }
const _hoisted_36 = { class: "justify-center" }
const _hoisted_37 = {
  key: 3,
  class: "shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl p-6 bg-gray-100",
  id: "profile",
  role: "tabpanel",
  "aria-labelledby": "profile-tab"
}
const _hoisted_38 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TokenAnalyticsChart = _resolveComponent("TokenAnalyticsChart")!
  const _component_apexchart = _resolveComponent("apexchart")!
  const _component_CardDesign = _resolveComponent("CardDesign")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("select", {
            id: "server-day",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected_router) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getHostsInfo($event))),
            class: "text-[14px] max-w-[200px] ml-auto border-[0rem] bgwhite rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
          }, [
            _cache[6] || (_cache[6] = _createElementVNode("option", {
              value: "",
              disabled: ""
            }, "Select a mini server", -1)),
            _cache[7] || (_cache[7] = _createElementVNode("option", { value: "all" }, "All Boxes", -1)),
            (_ctx.filterrouter.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.filterrouter, (option) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: option.host,
                    key: option.id
                  }, _toDisplayString(option.ssid ? option.ssid + ' - ' + option.host.split('_')[1] : option.host.split('_')[1]), 9, _hoisted_5))
                }), 128))
              : _createCommentVNode("", true)
          ], 544), [
            [_vModelSelect, _ctx.selected_router]
          ]),
          (_ctx.selected_router && _ctx.selected_router !== 'all' && _ctx.info.is_online)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6))
            : _createCommentVNode("", true),
          (_ctx.selected_router && _ctx.selected_router !== 'all' && !_ctx.info.is_online)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("ul", _hoisted_10, [
              _createElementVNode("li", _hoisted_11, [
                _createElementVNode("button", {
                  class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                    _ctx.activetab === '1'
                      ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]]),
                  id: "profile-tab",
                  "data-tabs-target": "#profile",
                  type: "button",
                  role: "tab",
                  "aria-controls": "profile",
                  "aria-selected": "false",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.activetab = '1'))
                }, " Wi-Fi Analysis ", 2)
              ]),
              _createElementVNode("li", _hoisted_12, [
                _createElementVNode("button", {
                  class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                    _ctx.activetab === '2'
                      ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]]),
                  id: "dashboard-tab",
                  "data-tabs-target": "#dashboard",
                  type: "button",
                  role: "tab",
                  "aria-controls": "dashboard",
                  "aria-selected": "false",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.activetab = '2'))
                }, " Customer Insights Analysis ", 2)
              ]),
              _createElementVNode("li", _hoisted_13, [
                _createElementVNode("button", {
                  class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                    _ctx.activetab === '3'
                      ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]]),
                  id: "settings-tab",
                  "data-tabs-target": "#settings",
                  type: "button",
                  role: "tab",
                  "aria-controls": "settings",
                  "aria-selected": "false",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.activetab = '3'))
                }, " Network Analytics ", 2)
              ]),
              _createElementVNode("li", _hoisted_14, [
                _createElementVNode("button", {
                  class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                    _ctx.activetab === '4'
                      ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]]),
                  id: "settings-tab",
                  "data-tabs-target": "#settings",
                  type: "button",
                  role: "tab",
                  "aria-controls": "settings",
                  "aria-selected": "false",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.activetab = '4'))
                }, " Service Status ", 2)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            (_ctx.activetab === '1')
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_TokenAnalyticsChart, {
                      data: _ctx.tokenAnalyticsData,
                      onFilterChange: _ctx.fetchTokenAnalyticsData,
                      currentTimeRange: _ctx.currentTimeRange
                    }, null, 8, ["data", "onFilterChange", "currentTimeRange"])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("a", _hoisted_19, [
                      _cache[8] || (_cache[8] = _createElementVNode("h3", { class: "text-base font-bold" }, " Device Vendor Distribution ", -1)),
                      _createElementVNode("div", _hoisted_20, [
                        (_ctx.vendorChartSeries.length)
                          ? (_openBlock(), _createBlock(_component_apexchart, {
                              key: 0,
                              width: "670",
                              type: "pie",
                              options: _ctx.vendorChartOptions,
                              series: _ctx.vendorChartSeries,
                              labels: _ctx.vendorChartLabels
                            }, null, 8, ["options", "series", "labels"]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_21, " No device vendor analytics available yet "))
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.activetab === '2')
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createVNode(_component_CardDesign, {
                      data: {
                  topic: 'Dwell Time',
                  icon: 'fa-solid fa-stopwatch',
                  figures: _ctx.insightAnalytics.dwell_time,
                  color: '#E5E4FF',
                  iconcolor: '#8280FF',
                }
                    }, null, 8, ["data"]),
                    _createVNode(_component_CardDesign, {
                      data: {
                  topic: 'Returned Customer',
                  icon: 'fa-solid fa-cube',
                  figures: _ctx.insightAnalytics.returned_customer,
                  color: '#FFF3D6',
                  iconcolor: '#FEC53D',
                }
                    }, null, 8, ["data"]),
                    _createVNode(_component_CardDesign, {
                      data: {
                  topic: 'Peek Time',
                  icon: 'fa-solid fa-clock',
                  figures: _ctx.insightAnalytics.peek_time,
                  color: '#D9F7E8',
                  iconcolor: '#00B69B',


                }
                    }, null, 8, ["data"]),
                    _createVNode(_component_CardDesign, {
                      data: {
                  topic: 'Unique Customers',
                  icon: 'fa-solid fa-users',
                  figures: _ctx.insightAnalytics.unique_customer,
                  color: '#EDF2F7',
                  iconcolor: '#006665',
                }
                    }, null, 8, ["data"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.activetab === '4')
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _createVNode(_component_CardDesign, {
                          data: {
                      topic: 'BOX LOCATION UPDATE',
                      icon: 'fa-solid fa-location-dot',
                      figures: _ctx.info.boxLocationUpdateServiceStatus,
                      color: '#E5E4FF',
                      iconcolor: '#8280FF',
                      no_zero: true,
                      small: true
                    }
                        }, null, 8, ["data"])
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createVNode(_component_CardDesign, {
                          data: {
                      topic: 'BOX OS VERSION UPDATE',
                      icon: 'fa fa-location',
                      figures: _ctx.info.boxOSVersionUpdateServiceStatus,
                      color: '#4AD991',
                      no_zero: true,
                      small: true

                    }
                        }, null, 8, ["data"])
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _createVNode(_component_CardDesign, {
                          data: {
                      topic: 'CAPTIVE PORTAL DATABASE INITIALIZATION',
                      icon: 'fa-solid fa-database',
                      figures: _ctx.info.captivePortalDbInitServiceStatus,
                      color: '#FFF3D6',
                      iconcolor: '#FEC53D',
                      no_zero: true,
                      small: true

                    }
                        }, null, 8, ["data"])
                      ]),
                      _createElementVNode("div", _hoisted_30, [
                        _createVNode(_component_CardDesign, {
                          data: {
                      topic: 'CAPTIVE PORTAL DATABASE SYNC',
                      icon: 'fa-solid fa-rotate',
                      figures: _ctx.info.captivePortalDbSyncServiceStatus,
                      iconcolor: '#FF9066',
                      color: '#FFDED1',
                      no_zero: true,
                      small: true

                    }
                        }, null, 8, ["data"])
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _createVNode(_component_CardDesign, {
                          data: {
                      topic: 'COOVACHILLI',
                      icon: 'fa-solid fa-microchip',
                      figures: _ctx.info.coovaChilliServiceStatus,
                      color: '#E5E4FF',
                      iconcolor: '#8280FF',
                      no_zero: true,
                      small: true

                    }
                        }, null, 8, ["data"])
                      ]),
                      _createElementVNode("div", _hoisted_32, [
                        _createVNode(_component_CardDesign, {
                          data: {
                      topic: 'HOSTAPD',
                      icon: 'fa-solid fa-wifi',
                      figures: _ctx.info.hostapdServiceStatus,
                      color: '#4AD991',
                      no_zero: true,
                      small: true
                    }
                        }, null, 8, ["data"])
                      ]),
                      _createElementVNode("div", _hoisted_33, [
                        _createVNode(_component_CardDesign, {
                          data: {
                      topic: 'MEDIA SYNC',
                      icon: 'fa-solid fa-file',
                      figures: _ctx.info.mediaSyncServiceStatus,
                      color: '#FFF3D6',
                      iconcolor: '#FEC53D',
                      no_zero: true,
                      small: true

                    }
                        }, null, 8, ["data"])
                      ]),
                      _createElementVNode("div", _hoisted_34, [
                        _createVNode(_component_CardDesign, {
                          data: {
                      topic: 'SSID CHANGE',
                      icon: 'fa-solid fa-signature',
                      figures: _ctx.info.ssidServiceStatus,
                      iconcolor: '#FF9066',
                      color: '#FFDED1',
                      no_zero: true,
                      small: true

                    }
                        }, null, 8, ["data"])
                      ]),
                      _createElementVNode("div", _hoisted_35, [
                        _createVNode(_component_CardDesign, {
                          data: {
                      topic: 'WEBMIN',
                      icon: 'fa-solid fa-spider',
                      figures: _ctx.info.webminServiceStatus,
                      color: '#E5E4FF',
                      iconcolor: '#8280FF',
                      no_zero: true,
                      small: true

                    }
                        }, null, 8, ["data"])
                      ]),
                      _createElementVNode("div", _hoisted_36, [
                        _createVNode(_component_CardDesign, {
                          data: {
                      topic: 'MOTIONEYE',
                      icon: 'fa-solid fa-camera',
                      figures: _ctx.info.motionEyeServiceStatus,
                      color: '#4AD991',
                      no_zero: true,
                      small: true
                    }
                        }, null, 8, ["data"])
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.activetab === '3')
              ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                  _createElementVNode("div", _hoisted_38, [
                    _createVNode(_component_CardDesign, {
                      data: {
                  topic: 'Network Bandwidth',
                  icon: 'fa-solid fa-network-wired',
                  network_figures: `${_ctx.routersAvgSpeed.average_upload_speed.toFixed(2)} MBPS
                    UPLOAD /
                    ${_ctx.routersAvgSpeed.average_download_speed.toFixed(2)} MBPS
                    DOWNLOAD`,
                  color: '#4AD991',
                  network: true
                }
                    }, null, 8, ["data"]),
                    _createVNode(_component_CardDesign, { data: {
                  topic: 'Network Latency',
                  icon: 'fa-solid fa-mobile-button',
                  figures: `0.00 MBs`,
                  color: '#FFF3D6',
                  iconcolor: '#FEC53D',

                } }),
                    _createVNode(_component_CardDesign, {
                      data: {
                  topic: 'Network Usage',
                  icon: 'fa-solid fa-server',
                  network_figures: `  ${_ctx.routersNetUsage.total_data_transmitted_mb.toFixed(2)}
                    MBs UPLOADED /
                    ${_ctx.routersNetUsage.total_data_received_mb.toFixed(2)}
                    MBs DOWNLOADED`,
                  color: '#FEE7EC',
                  iconcolor: '#F93C65',
                  network: true
                }
                    }, null, 8, ["data"]),
                    _createVNode(_component_CardDesign, {
                      data: {
                  topic: 'Signal Strength',
                  icon: 'fa-solid fa-chart-line',
                  figures: `${_ctx.router_signal_strength}`,
                  color: '#E5E4FF',
                  iconcolor: '#8280FF',

                }
                    }, null, 8, ["data"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}