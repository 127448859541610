import { resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "w-full my-3"
}
const _hoisted_2 = { class: "min-server text-right mb-2 flex items-center" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100 bg-white mt-6" }
const _hoisted_5 = { class: "w-full lg:flex my-6 justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_suspended_page_message = _resolveComponent("suspended-page-message")!
  const _component_TokenAnalyticsChart = _resolveComponent("TokenAnalyticsChart")!
  const _component_SmeAnalytics = _resolveComponent("SmeAnalytics")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "Dashboard",
      icon: _ctx.icon.dashboard
    }, {
      aside: _withCtx(() => _cache[2] || (_cache[2] = [])),
      _: 1
    }, 8, ["icon"]),
    (!_ctx.loggedInUser.sme?.is_active)
      ? (_openBlock(), _createBlock(_component_suspended_page_message, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRouter) = $event)),
              onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleRouterChange && _ctx.handleRouterChange(...args))),
              class: "text-[14px] max-w-[250px] ml-auto border-[0rem] bgwhite rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
            }, [
              _cache[3] || (_cache[3] = _createElementVNode("option", { value: "all" }, "All Boxes", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loggedInUser.partner.routers, (router) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: router.serial_number,
                  value: router.serial_number
                }, _toDisplayString(router.ssid ? router.ssid + ' - ' + router.serial_number : router.serial_number), 9, _hoisted_3))
              }), 128))
            ], 544), [
              [_vModelSelect, _ctx.selectedRouter]
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_TokenAnalyticsChart, {
              data: _ctx.tokenAnalyticsData,
              onFilterChange: _ctx.fetchTokenAnalyticsData,
              currentTimeRange: _ctx.currentTimeRange
            }, null, 8, ["data", "onFilterChange", "currentTimeRange"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_SmeAnalytics, {
              weekly_data: _ctx.weeklyUsers,
              monthly_data: _ctx.monthlyUsers,
              yearly_data: _ctx.yearlyUsers
            }, null, 8, ["weekly_data", "monthly_data", "yearly_data"]),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "bg-white lg:w-[49%] min-h-[21.432rem] rounded-[0.938rem] pt-4 pb-6 px-6 shadow lg:mb-0 mb-5" }, [
              _createElementVNode("span", { class: "font-poppins font-semibold text-[0.9rem] text-[#212121]" }, "Map"),
              _createElementVNode("div", {
                class: "w-full h-[400px] mt-3",
                id: "map"
              })
            ], -1))
          ])
        ]))
  ], 64))
}